<template>
  <v-card>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snackbar_text }}
    </v-snackbar>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-data-table :headers="headers" :items="params" hide-default-footer>
      <template v-slot:item.id="{ item }"> # {{ item.id }} </template>
      <template v-slot:item.point_number="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              v-bind="attrs"
              v-on="on"
              class="link_label"
              :to="{
                name: 'point_info',
                params: { point_id: item.point_number },
              }"
              >{{ item.point_number }}
            </router-link>
          </template>
          <span>Копировать</span>
        </v-tooltip>
      </template>
      <template v-slot:item.amount="{ item }"> {{ item.amount }} kWt </template>
      <template v-slot:item.full_name="{ item }">
        {{ "+" + item.phone }}
      </template>
      <template v-slot:item.duration="{ item }">
        {{ duration(item.duration) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              v-bind="attrs"
              v-on="on"
              class="text-decoration-none"
              :to="{
                name: 'charging_info',
                params: { charge_id: item.id },
              }"
              ><v-icon>mdi-open-in-new</v-icon></router-link
            >
          </template>
          <span>Открыть в новом окне</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "TableCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      snackbar: false,
      snackbar_text: "Текст скопирован в буфер",
      search: "",
      headers: [
        {
          text: "ID зарядки",
          align: "start",
          value: "id",
        },
        {
          text: "Клиент",
          align: "start",
          sortable: false,
          value: "full_name",
        },
        { text: "Станция", value: "point_number" },
        { text: "Разьем", value: "connector_type" },
        { text: "Заряд", value: "power_amount" },
        {
          text: "Продолжительность зарядки",
          value: "duration",
          align: "center",
        },
        { text: "Окончание зарядки", value: "end_time" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    copy_text(text) {
      navigator.clipboard.writeText(text);
      this.snackbar = true;
    },
    duration(time) {
      let end = time.split(".");
      if (end.length > 0) return end[0];
      return "00:00:00";
    },
  },
};
</script>

<style lang="scss">
.link_label {
  text-decoration: none;
  cursor: pointer;
}
</style>
