<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="4">
        <count-card
          :params="charging.params"
          title="Заправки"
          color="#00355e"
          type="area"
        >
          <template v-slot:headers>
            <div class="">Зарядки</div>
            <v-spacer></v-spacer>
            <v-btn text dark color="primary">Отчет</v-btn>
          </template>
          <template v-slot:body>
            <div class="pa-2">
              <div class="text-h4">{{ charging.total }} шт</div>
              <div class="primary--text text--lighten-1 mt-1">
                {{ charging.current_month }} шт за этот месяц
              </div>
            </div>
            <div class="spacer"></div>
            <div class="px-2 pb-2">
              <div class="title mb-1">Сегодняшние зарядки</div>
              <div class="d-flex text-center">
                <div class="text-h4">{{ charging.today }} шт</div>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column text-right">
                  <div class="font-weight-bold">
                    <span>
                      <span class="success--text">
                        <v-icon color="success" size="16"
                          >mdi-arrow-top-right</v-icon
                        >
                        {{
                          charging.last_week > 0
                            ? charging.last_month *
                              (charging.current_month / 100)
                            : charging.current_month * 100
                        }}%
                      </span>
                    </span>
                    <div class="caption">по сравнению с прошлым месяцем</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </count-card>
      </v-col>
      <v-col cols="12" lg="4">
        <count-card
          :params="clients.params"
          title="Клиенты"
          color="#00355e"
          type="area"
        >
          <template v-slot:headers>
            <div class="">Клиенты</div>
            <v-spacer></v-spacer>
            <v-btn text dark color="primary">Отчет</v-btn>
          </template>
          <template v-slot:body>
            <div class="pa-2">
              <div class="text-h4">{{ clients.total }}</div>
              <div class="primary--text text--lighten-1 mt-1">
                на {{ clients.last_week }} больше прошлой недели
              </div>
            </div>
            <div class="spacer"></div>
            <div class="px-2 pb-2">
              <div class="title mb-1">Новые клиенты</div>
              <div class="d-flex text-center">
                <div class="text-h4">{{ clients.current_week }}</div>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column text-right">
                  <div class="font-weight-bold">
                    <span>
                      <span class="success--text">
                        <v-icon color="success" size="16"
                          >mdi-arrow-top-right</v-icon
                        >
                        {{ clients.last_week * (clients.current_week / 100) }}%
                      </span>
                    </span>
                    <div class="caption">по сравнению с прошлым месяцем</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </count-card>
      </v-col>
      <v-col cols="12" lg="4">
        <count-card
          :params="points.params"
          color="#00355e"
          type="donut"
          height="318"
          legend
        >
          <template v-slot:headers>
            <div class="">Топ зарядных станций за 7 дней</div>
            <v-spacer></v-spacer>
            <v-btn text dark color="primary">Отчет</v-btn>
          </template>
        </count-card>
      </v-col>
      <v-col>
        <table-card title="Недавние зарядки" :params="latest" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CountCard from "../components/card/CountCard";
import TableCard from "../components/card/TableCard";

export default {
  name: "Dashboard",
  components: {
    CountCard,
    TableCard,
  },
  created() {
    this.$store.dispatch("dashboard");
    this.$store.dispatch("latest_charges");
    this.pollData();
  },
  data() {
    return {
      polling: null,
    };
  },
  computed: {
    charging() {
      return this.$store.getters.DASHBOARD.charges;
    },
    clients() {
      return this.$store.getters.DASHBOARD.clients;
    },
    points() {
      return this.$store.getters.DASHBOARD.points;
    },
    latest() {
      return this.$store.getters.LATEST_CHARGES;
    },
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.$store.dispatch("dashboard");
        this.$store.dispatch("latest_charges");
      }, 20000);
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>

<style scoped></style>
