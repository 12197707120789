var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-snackbar',{attrs:{"timeout":5000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.params,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" # "+_vm._s(item.id)+" ")]}},{key:"item.point_number",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',_vm._g(_vm._b({staticClass:"link_label",attrs:{"to":{
              name: 'point_info',
              params: { point_id: item.point_number },
            }}},'router-link',attrs,false),on),[_vm._v(_vm._s(item.point_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Копировать")])])]}},{key:"item.amount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" kWt ")]}},{key:"item.full_name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s("+" + item.phone)+" ")]}},{key:"item.duration",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.duration(item.duration))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('router-link',_vm._g(_vm._b({staticClass:"text-decoration-none",attrs:{"to":{
              name: 'charging_info',
              params: { charge_id: item.id },
            }}},'router-link',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Открыть в новом окне")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }